import { db } from "./db";
import { loadObj } from "./dbutils";
import { getLog } from "./log";
let log = getLog('lib-user');

export async function addUsertoEvent(uid, event) {
  log.log(`addUsertoEvent ${uid} ${event.id}`);
  let userIds = event.userIds || [];
  let p = [];
  if (!userIds.includes(uid)) {
    userIds.push(uid);
    p.push(db.collection(`LiveEvents`).doc(event.id).update({userIds}))
  }
  await Promise.allSettled(p);
}

export async function addUsertoEventId(uid, eventId) {
  log.log(`addUsertoEventId ${uid} ${eventId}`);
  let event = await loadObj(db.collection(`LiveEvents`).doc(eventId));
  await addUsertoEvent(uid, event);
}

export async function registerUserToEvent(user, eventId) {
  log.log(`registerUserToEvent ${user.id} ${eventId}`);
  let events = user.eventRegistrations || [];
  if (!events.includes(eventId)) {
    events.push(eventId);
    db.collection("LiveUsers").doc(user.id).update({"eventRegistrations": events});
  }
}

export async function removeUserFromEvent(uid, event) {
  log.log(`removeUserFromEvent ${uid} ${event.id}`);
  let userIds = event.userIds || [];
  log.log(`event users`, event);
  let promises = [];
  if (userIds.includes(uid)) {
    userIds = userIds.filter(u => u != uid);
    log.log(`new userIds`, userIds);
    promises.push(
      db.collection(`LiveEvents`).doc(event.id).update({userIds}),
    );
  }
  promises.push(
    db.collection(`LiveEvents/${event.id}/users`).doc(uid).delete().then(() => {
      log.log(`eventUser ${uid} deleted`);
    }).catch((err) => {
      log.log(`could not delete eventUser ${uid}. ${err}`);
    })
  );
  await Promise.allSettled(promises);
}

export async function addUserToEventQueue(event) {
  let queueLength = event.queueLength || 0;
  let position = queueLength;
  queueLength += 1;
  await db.collection("LiveEvents").doc(event.id).update({queueLength});
  return position;
}

import * as pick from "object.pick";

export async function addMatches(uid, matches, eventId) {
  log.log(`addMatches ${uid}`);
  let col = db.collection(`LiveUsers/${uid}/matches`);
  let date = new Date();
  matches.forEach(u => {
    let match = pick(u, ["age", "gender", "picture0", "picture1", "name", "location", "bio", "contactType", "contact"]);
    match["date"] = date;
    match["eventId"] = eventId;
    col.doc(u.id).set(match);
  });
}

export async function updateUser(uid, data) {
  await db.collection(`LiveUsers`).doc(uid).update(data);
}
